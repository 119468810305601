import {Navigate, Outlet} from 'react-router-dom'
import { useAuth } from './auth'

export const RequireAuth = () => {
    const auth:any=useAuth()
    if(!auth.user)
    {
      let typeLogin=window.localStorage.getItem("typeLogin")
      if(typeLogin&&typeLogin.length>2){
        return <Navigate to={`${typeLogin}/login`}/>
      }else{
        return <Navigate to='/login'/>
      }
        
    }
  return <Outlet/>
}
