import './scss/style.scss';
import { AuthProvider, getToken, useAuth } from './components/auth';
import { Navigate, Route, Routes } from "react-router-dom";
import Login from './pages/auth/login';
import { RequireAuth } from './components/requireAuth';
import { AuthLayout } from './pages/auth/auth';
import { ForgotPassword } from './pages/auth/forget';
import { ResetPassword } from './pages/auth/reset';
import { AppMainLogo, ForgotPasswordSubTitle, ForgotPasswordTitle, LogInSubTitle, LogInTitle, ResetPasswordImage, ResetPasswordSubTitle, ResetPasswordTitle } from './pages/auth/constant';
import UserList from './pages/user/list';
import { Layout } from './pages/layout/layout';
import { RoleList } from './pages/role/list';
import { CompanyList } from './pages/company/list';
import { JobList } from './pages/job/job';
import { BalanceSheet } from './pages/balanceSheet/balanceSheet';
import { EmailTemplateList } from './pages/emailTemplate/list';
import Page404 from './pages/page404';
import { AppProvider } from './components/app';
import { DeleteListInfo } from './pages/modification/deleteInfo';
import { ModificationInfo } from './pages/modification/modifictionInfo';
import { LoadingProvider } from './helper/loaderHelper';
import Usersignup from './pages/auth/userSignup';
import Usersignin from './pages/auth/userSignin';
import Price from './pages/price';
import About from './pages/about';
import Userguide from './pages/userguide';
import Contactus from './pages/contactus';

function App() {
  let userData = localStorage.getItem("token") ? JSON.parse(localStorage?.getItem("token")!) : ""

  let routesPrefix = ""
  if (userData.role == "User") {
    let loginType = userData?.loginType ? userData?.loginType : userData?.loginFrom
    routesPrefix = "/" + loginType;
  }

  return (
    <LoadingProvider>
      <AuthProvider>
        <AppProvider>
          <Routes>
            <Route path='/signUp' element={<Usersignup />} />
            <Route path='/xero/signUp' element={<Usersignup />} />
            <Route path='/QBO/signUp' element={<Usersignup />} />
            <Route path='/zoho/signUp' element={<Usersignup />} />
            {/* <Route path='/signin' element={<Usersignin />} /> */}
            <Route path='/price' element={<Price />} />
            <Route path='/userguide' element={<Userguide />} />
            <Route path='/xero/userguide' element={<Userguide />} />
            <Route path='/QBO/userguide' element={<Userguide />} />
            <Route path='/zoho/userguide' element={<Userguide />} />
            <Route path='/login' element={<AuthLayout Title={LogInTitle} SubTitle={LogInSubTitle} Image={AppMainLogo}><Login /></AuthLayout>} />

            {/* contactus Routes */}
            <Route path='/contactus' element={<Contactus />} />
            <Route path='/xero/contactus' element={<Contactus />} />
            <Route path='/QBO/contactus' element={<Contactus />} />
            <Route path='/zoho/contactus' element={<Contactus />} />
            {/* contactus Routes */}

            {/* contactus Routes */}
            <Route path='/about' element={<About />} />
            <Route path='/xero/about' element={<About />} />
            <Route path='/QBO/about' element={<About />} />
            <Route path='/zoho/about' element={<About />} />
            {/* contactus Routes */}

            {/* xero router */}
            <Route path='/xero/login' element={<AuthLayout Title={LogInTitle} SubTitle={LogInSubTitle} Image={AppMainLogo}><Login /></AuthLayout>} />
            <Route path='/odoo/login' element={<AuthLayout Title={LogInTitle} SubTitle={LogInSubTitle} Image={AppMainLogo}><Login /></AuthLayout>} />
            <Route
              path='/xero'
              element={<Navigate to="/xero/login" />}
            />
            {/* xero router */}
            {/* qbo router */}
            {/* <Route
              path='/qbo/login'
              element={<Navigate to="/QBO/login" />}
            /> */}
            <Route path='/zoho/login' element={<AuthLayout Title={LogInTitle} SubTitle={LogInSubTitle} Image={AppMainLogo}><Login /></AuthLayout>} />

            {/* qbo router */}
            <Route path='/QBO/login' element={<AuthLayout Title={LogInTitle} SubTitle={LogInSubTitle} Image={AppMainLogo}><Login /></AuthLayout>} />


            <Route path='/ForgotPassword' element={<AuthLayout Title={ForgotPasswordTitle} SubTitle={ForgotPasswordSubTitle} Image={ResetPasswordImage}><ForgotPassword /></AuthLayout>} />
            <Route path='/reset/:token' element={<AuthLayout Title={ResetPasswordTitle} SubTitle={ResetPasswordSubTitle} Image={ResetPasswordImage}><ResetPassword /></AuthLayout>} />
            <Route element={<RequireAuth />}>
              <Route element={<Layout />}>
                <Route path='/' element={<CompanyList />} />
                <Route path={'/user'} element={<UserList />} />
                <Route path={"/conversation"} element={<CompanyList />} />
                <Route path={"/job"} element={<JobList />} />
                <Route path={"/delete"} element={<DeleteListInfo />} />
                <Route path={"/modification"} element={<ModificationInfo />} />
                <Route path={"/balanceSheet"} element={<BalanceSheet />} />

                <Route path={routesPrefix + '/user'} element={<UserList />} />
                <Route path={routesPrefix + "/conversation"} element={<CompanyList />} />
                <Route path={routesPrefix + "/job"} element={<JobList />} />
                <Route path={routesPrefix + "/delete"} element={<DeleteListInfo />} />
                <Route path={routesPrefix + "/modification"} element={<ModificationInfo />} />
                <Route path={routesPrefix + "/balanceSheet"} element={<BalanceSheet />} />
                {
                  !routesPrefix && <>
                    <Route path='/role' element={<RoleList />} />
                    <Route path='/tenant' element={<UserList />} />
                    <Route path='/emailTemplate' element={<EmailTemplateList />} />
                  </>
                }

              </Route>
            </Route>
            <Route path='*' element={<Page404 />} />
          </Routes>
        </AppProvider>
      </AuthProvider>
    </LoadingProvider>

  );
}

export default App;
